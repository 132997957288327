import React, { useState, Fragment, useContext } from 'react';
import { makeStyles, AppBar, Tabs, Tab, Typography, ListItem } from '@material-ui/core';
import { capitalizeFirst } from '../utils/helpers';
import ReviewsSection from '../features/Reviews/ReviewsSection';
import DescriptionBox from './DescriptionBox';
import PreviewContext from '../PreviewContext';
// import { brandProductStores } from "../utils/example-data";
import FeaturedListItem from './FeaturedListItem';
import { FEATURED_TYPES, NONE } from '../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  whereToFindPanel: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    padding: theme.spacing(4, 2, 4, 2),
  },
  storeListCard: {
    width: 232,
    [theme.breakpoints.down('md')]: {
      width: 192,
    },
  },
  descriptionPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  reviewsPanel: {
    margin: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  nameAndDescription: {
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  title: {
    fontSize: '22pt',
    marginBottom: theme.spacing(2),
  },
  flavourHeader: {
    fontSize: '12pt',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      margin: 0,
      marginTop: theme.spacing(1),
    },
  },
  symbolListHeader: {
    paddingLeft: theme.spacing(1),
  },
  symbolList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  symbol: {
    width: '6em',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '4em',
    },
  },
  symbolWithText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  midTabBar: {
    // marginBottom: 20,
    // zIndex: 97,
    boxShadow: '0px 0px',
    // borderBottom: "1px solid #e0e0e0",
    // margin: theme.spacing(0, 0.5, 0, 0.5)
    backgroundColor: 'transparent',
  },
  tabRoot: {
    // border: '0px',
  },
  indicator: {
    position: 'absolute',
    height: 4,
    top: 0,
    transition: 'none',
  },
  storeTab: {
    /* TODO - DRY across all detail screens */
    minWidth: 40,
    color: theme.palette.common.black,
    backgroundColor: '#ebebeb',
    // margin: theme.spacing(0, 1, 0, 0),
    border: '1px solid #e0e0e0',
    fontWeight: '700',
    fontSize: '14pt',
    zIndex: 90,
    // paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  storeTabSelected: {
    minWidth: 40,
    color: theme.palette.secondary.main,
    fontWeight: '700',
    // margin: theme.spacing(0, 1, 0, 0),
    zIndex: 92,
    border: '1px solid #e0e0e0',
    borderTop: '0px',
    borderBottom: '1px solid #ffffff',
    fontSize: '14pt',
    // paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },
  panelContainer: {
    border: '1px solid #e0e0e0',
    zIndex: 91,
    marginTop: -1,
    // marginRight: theme.spacing(1)
  },
  effectsAndUses: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginTop: theme.spacing(1),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function ProductDescription(props) {
  const classes = useStyles();

  const { product } = props;

  return (
    <div className={classes.descriptionPanel}>
      <div className={classes.nameAndDescription}>
        <Typography variant="h4" className={classes.title}>
          {product.name}
        </Typography>
        {/* <Typography variant="body1">{product.description}</Typography> */}
        {<DescriptionBox text={product.description ? product.description : 'N/A'} fullMode></DescriptionBox>}
        {product.flavours.length > 0 && (
          <Typography className={classes.flavourHeader}>
            <strong>Flavour Profile: </strong>
            {product.flavours ? product.flavours.join(', ') : 'N/A'}
          </Typography>
        )}
      </div>
      {(product.effects.length > 0 || product.uses.length > 0) && (
        <div className={classes.effectsAndUses}>
          {product.effects.length > 0 && (
            <Fragment>
              <Typography variant="h6" className={classes.symbolListHeader}>
                Typical Effects
              </Typography>
              <div className={classes.symbolList}>
                {product.effects &&
                  product.effects.map((effect, index) => (
                    <div key={index} className={classes.symbolWithText}>
                      <img
                        src={require(`../assets/effects/${effect.toLowerCase().replace(/ /g, '')}.svg`)}
                        alt={`effect-${effect}`}
                        className={classes.symbol}
                        key={index}
                      ></img>
                      <Typography variant="caption">{capitalizeFirst(effect)}</Typography>
                    </div>
                  ))}
              </div>
            </Fragment>
          )}
          {product.uses.length > 0 && (
            <Fragment>
              <Typography variant="h6" className={classes.symbolListHeader}>
                Common Uses
              </Typography>
              <div className={classes.symbolList}>
                {product.uses &&
                  product.uses.map((use, index) => (
                    <div key={index} className={classes.symbolWithText}>
                      <img
                        src={require(`../assets/uses/${use.toLowerCase().replace(/ /g, '')}.svg`)}
                        alt={`use-${use}`}
                        className={classes.symbol}
                      ></img>
                      <Typography variant="caption">{capitalizeFirst(use)}</Typography>
                    </div>
                  ))}
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
}

function ProductFinder(props) {
  const classes = useStyles();

  const { product } = props;
  // const productStores = brandProductStores;

  return (
    <div className={classes.whereToFindPanel}>
      {/* {console.log(product)} */}
      {product.stores.map(
        (store, index) =>
          store.published && (
            <ListItem className={classes.storeListCard} key={index}>
              <FeaturedListItem
                verified={
                  product.productCustomizations.find(
                    (custom) => custom.store._links.self.href.replace('{projection}', '') === store._links.self.href
                  ) &&
                  product.productCustomizations.find(
                    (custom) => custom.store._links.self.href.replace('{projection}', '') === store._links.self.href
                  ).verified
                }
                item={store}
                key={index}
                type={store.type === 'Storefront' ? FEATURED_TYPES.STORE : FEATURED_TYPES.DELIVERY}
                // ao: add location to fix redirection from where-to-find tab
                location={{
                  city: store.address.city,
                  province: store.address.province,
                }}
                // onClick={() => onStoreClick()}
                onClick={(event) => {}}
                query={{
                  category: product.categories.subsubcategory
                    ? product.categories.subsubcategory
                    : product.categories.subcategory
                    ? product.categories.subcategory
                    : product.categories.category,
                  parentCategory: product.categories.subsubcategory
                    ? product.categories.subcategory
                    : product.categories.subcategory
                    ? product.categories.category
                    : NONE,
                  // ao: SDAW-1044 - only assign brand value if brand-product to prevent error with store-undefined product.
                  brand: product.brand && {
                    id: product.brand._links.self.href.replace('{?projection}', ''),
                    name: product.brand.name,
                  },
                }}
              ></FeaturedListItem>
            </ListItem>
          )
      )}
      {/* ao: SDAW-1044 - include non-brand products that only belong to unpublished stores */}
      {(product.stores.length === 0 || product.stores.filter((store) => store.published).length === 0) && (
        <Typography style={{ marginLeft: '10px' }}>
          This product is not yet available in any retail locations.
        </Typography>
      )}
    </div>
  );
}

function ProductDetailsTabPanel(props) {
  const classes = useStyles();

  const preview = useContext(PreviewContext);

  // ao: SDAW-1044 assign storeId from props
  const { product, id, brand, storeId } = props;

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // ao: SDAW-1044 - offset when store unkown too, to display where-to-find tab
  const offset = brand || !storeId ? 1 : 0;

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.midTabBar}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="tabs"
          variant="fullWidth"
          classes={{ indicator: classes.indicator, root: classes.tabRoot }}
        >
          <Tab
            label="Description"
            id={`tab-description`}
            aria-controls={`tab-description`}
            className={value === 0 ? classes.storeTabSelected : classes.storeTab}
          />
          {/* ao: SDAW-1044 - add Where to find tab when store undefined */}
          {(brand || !storeId) && (
            <Tab
              label="Where To Find"
              id={`tab-where`}
              aria-controls={`tab-where`}
              className={value === 1 ? classes.storeTabSelected : classes.storeTab}
            />
          )}
          <Tab
            label="Reviews"
            id={`tab-deals`}
            aria-controls={`tab-reviews`}
            className={value === 1 + offset ? classes.storeTabSelected : classes.storeTab}
          />
        </Tabs>
      </AppBar>
      <div className={classes.panelContainer}>
        {/* ao: SDAW-1044 - add Where to find tab when store undefined */}
        {(brand || !storeId) && value === 1 && preview.state.screen && (
          <div className={classes.reviewsPanel}>
            <Typography>Not available in preview mode.</Typography>
          </div>
        )}
        {(brand || !storeId) && value === 1 && !preview.state.screen && (
          <ProductFinder product={product}></ProductFinder>
        )}
        {value === 0 && <ProductDescription product={product}></ProductDescription>}
        {value === 1 + offset && (
          <div className={classes.reviewsPanel}>
            {!preview.state.screen && (
              <ReviewsSection
                // product={true}
                type="product"
                itemId={id}
                ownerName={product.brand ? product.brand.name : product.stores[0].name}
                disableReviews={props.disableReviews === true}
                productInfo={product} //{product.brand ? product.brand : product.stores[0]}
              />
            )}
            {preview.state.screen && <Typography>Not available in preview mode.</Typography>}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductDetailsTabPanel;

import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import ItemOwnerActions from './ItemOwnerActions';

export default function ReviewReply({
  review,
  classes,
  setModifiedReview,
  modifiedReview,
  mode,
  setMode,
  saving,
  isItemOwner,
  isAdmin,
}) {
  const hasReply = !!review.reply;

  const updateReply = (e) => {
    const { value } = e.target;
    setModifiedReview({ ...modifiedReview, reply: value });
  };
  const readReply = (
    <>
      <Typography className={classes.replyAuthorsName}>{`${review.replyAuthorName} replied:`}</Typography>
      <Typography className={classes.reviewReplyText}>{review.reply}</Typography>
    </>
  );

  const editReply = (
    <>
      <TextField
        label="Reply"
        name="reply"
        variant="outlined"
        id="review-input"
        type="text"
        multiline
        rows="4"
        inputProps={{ maxLength: 500 }}
        helperText={`${modifiedReview.reply?.length ?? 0}/500 characters`}
        //   defaultValue={review.description}
        onChange={updateReply}
        value={modifiedReview.reply}
      />
    </>
  );
  return (
    <>
      {(hasReply || isItemOwner) && (
        <section
          className={classes.reviewReplySection}
          // style={hasReply ? { borderTop: '1px solid lightgrey' } : { borderTop: 'none' }}
        >
          {hasReply && mode !== 'edit' && readReply}
          {mode === 'edit' && editReply}
          {(isItemOwner || isAdmin) && (
            <ItemOwnerActions
              review={review}
              saving={saving}
              mode={mode}
              setMode={setMode}
              modifiedReview={modifiedReview}
              setModifiedReview={setModifiedReview}
              isAdmin={isAdmin}
            />
          )}
        </section>
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { parseIdFromLink } from '../../../utils/helpers';
import api from '../../../utils/api';
import cookies from '../../../cookies';
import ReviewPost from './ReviewPost';
import ReviewReply from './ReviewReply';
import ReviewerDetails from './ReviewerDetails';

const useStyles = makeStyles((theme) => {
  const REVIEW_SECTION_PADDING = theme.spacing(2, 3);
  return {
    reviewItem: {
      margin: theme.spacing(1, 0),
      padding: REVIEW_SECTION_PADDING,
      border: '1px solid #ebebeb',
      overflow: 'hidden',
      borderRadius: 5,
    },
    reviewItemHeader: {
      margin: theme.spacing(1, 0),
      display: 'flex',
      gap: '0.5rem',
    },
    reviewerName: { fontWeight: 'bold' },

    ratingContainer: { display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: theme.spacing(1) },
    ratingNumbers: { fontWeight: 'bold' },

    title: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
    description: {
      whiteSpace: 'pre-wrap',
      overflow: 'wrap',
    },

    reviewReplySection: {
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      width: '100%',
    },
    daysSinceCreated: {
      alignContent: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.7rem',
      },
    },
    replyAuthorsName: {
      // margin: theme.spacing(1, 1, 0, 1),
      padding: theme.spacing(0.3, 1.5),
      border: '1px solid lightgrey',
      backgroundColor: 'lightgrey',
      color: 'black',
      borderRadius: '30px',
      fontWeight: 'bold',
      fontSize: '1rem',
      maxWidth: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    reviewReplyText: {
      border: '1px solid #d6d6d6',
      borderRadius: 5,
      padding: REVIEW_SECTION_PADDING,
      whiteSpace: 'pre-wrap',
      overflow: 'wrap',
      '& .MuiInputBase-root.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.65)', // (default alpha is 0.38)
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
  };
});

export default function ReviewItemIndex({ className, review, setReviews, setError, isItemOwner, isAdmin, ...props }) {
  const classes = useStyles();
  const reviewID = parseIdFromLink(review._links.self.href);
  //========================= State ==========================
  const [reviewMode, setReviewMode] = useState('read'); //read / edit / saving /confirmDelete - set in reviewActions
  const [replyMode, setReplyMode] = useState('read'); //read /edit/ saving/ confirmDelete
  const [saving, setSaving] = useState(false); // Triggers API useEffect

  const [modifiedReview, setModifiedReview] = useState({
    title: review.title,
    description: review.description,
    rating: review.rating,
    reply: review.reply,
  });

  //=================== UseEffect to to API ===================
  useEffect(() => {
    let isMounted = true; //to prevent useEffect from running twice
    const updateReview = async () => {
      console.log('SAVE REVIEW TO API, update state and change mode');
      setError('');
      setSaving(true);
      try {
        //update review
        await api.updateReview(reviewID, modifiedReview);
        if (isMounted) {
          //Update state
          //Update review object
          setReviews((reviews) =>
            reviews.map((r) => {
              if (parseIdFromLink(r._links.self.href) === reviewID) {
                return {
                  ...r,
                  title: modifiedReview.title,
                  description: modifiedReview.description,
                  rating: modifiedReview.rating,
                  reply: modifiedReview.reply,
                };
              }
              return r;
            })
          );

          setReviewMode('read');
          setReplyMode('read');
        }
      } catch (error) {
        setError('ERROR'); //TODO basic error handling and message - refactor for more detail.
      } finally {
        setSaving(false);
      }
    };
    const deleteReview = async () => {
      console.log('Delete review fr API then rmv review from state');
      setError('');
      setSaving(true);
      try {
        await api.deleteReview(reviewID); //delete review from API
        if (isMounted) {
          setReviews((reviews) => reviews.filter((r) => parseIdFromLink(r._links.self.href) !== reviewID)); //Remove review from state
          //Update state
          setReviewMode('read');
          setReplyMode('read');
        }
      } catch (error) {
        setError('ERROR'); //TODO basic error handling and message - refactor for more detail.
      } finally {
        setSaving(false);
      }
    };
    if (reviewMode === 'saving' || replyMode === 'saving' || replyMode === 'deleting') updateReview(); //Reply Delete only removed the reply field from the review
    if (reviewMode === 'deleting') deleteReview();

    return () => {
      isMounted = false;
    };
  }, [reviewMode, replyMode, reviewID, setReviews, setError]); // eslint-disable-line react-hooks/exhaustive-deps

  //=======================================================

  const userID = cookies.getUserID();
  const isReviewAuthor = userID === review.reviewerID;

  return (
    <div className={classes.reviewItem}>
      <ReviewPost
        review={review}
        isReviewAuthor={isReviewAuthor}
        classes={classes}
        modifiedReview={modifiedReview}
        setModifiedReview={setModifiedReview}
        reviewMode={reviewMode}
        setReviewMode={setReviewMode}
        saving={saving}
        isAdmin={isAdmin}
      />
      <ReviewReply
        isItemOwner={isItemOwner}
        review={review}
        classes={classes}
        saving={saving}
        mode={replyMode}
        setMode={setReplyMode}
        modifiedReview={modifiedReview}
        setModifiedReview={setModifiedReview}
        isAdmin={isAdmin}
      />
      {isAdmin && <ReviewerDetails review={review} />}
    </div>
  );
}

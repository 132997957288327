import React from 'react';
import { makeStyles } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LinkButton from './LinkButton';
import { NONE, STORE_TYPES } from '../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  breadcrumbWrapper: {
    [theme.breakpoints.down('sm')]: {
      '& * > .MuiBreadcrumbs-separator': {
        margin: 0,
      },
    },
  },
}));

function CategoryBreadcrumbs(props) {
  const classes = useStyles();

  const { store, categories, productName } = props;

  const type = store.type === STORE_TYPES[0] ? 'stores' : store.type === 'brand' ? 'brands' : 'deliveries';

  const urlLocation = window.location.href.split('/').slice(4, 6);
  const province = urlLocation[0];
  const city = urlLocation[1];
  const destinationPath =
    store.type === 'brand' ? `/${type}/${store.id}` : `/${type}/${province}/${city}/${store.id}/${store.slug}`;

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className={classes.breadcrumbWrapper}
      >
        <LinkButton label={store.name} to={{ pathname: destinationPath }}></LinkButton>
        {Object.keys(categories).map((categoryLevel) => {
          if (categories[`${categoryLevel}`]) {
            return (
              <LinkButton
                key={categoryLevel}
                label={categories[`${categoryLevel}`]}
                to={{
                  pathname: destinationPath,
                  query: {
                    category: categories[`${categoryLevel}`],
                    parentCategory:
                      categoryLevel === 'category'
                        ? NONE
                        : categoryLevel === 'subcategory'
                        ? categories.category
                        : categoryLevel === 'subsubcategory'
                        ? categories.subcategory
                        : 'N/A',
                  },
                }}
              ></LinkButton>
            );
          } else return null;
        })}
        <LinkButton label={productName} to="" disabled bold></LinkButton>
      </Breadcrumbs>
    </div>
  );
}

export default CategoryBreadcrumbs;

/* Rendering is based on parent - must be reviewAuthor
- Actions allow Edit or Delete
- EDIT MODE - will change review section to textarea / boxes allowing the user to edit directly in the list
  - Save and cancel buttons when in edit mode
- DELETE MODE - will confirm and delete the users' review

*/
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  reviewActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
  },
  saveProgress: { marginBottom: '5px', marginTop: '0px', marginRight: '5px' },
  actionButtons: {
    width: 24,
    height: 24,
    padding: '6px',
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'lightgray',
      borderRadius: '100%',
      transition: 'all .2s ease-in-out',
      cursor: 'pointer',
    },
  },
  deleteConfirmation: {
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      display: 'flex',
      alignSelf: 'flex-end',
      gap: '16px',
    },
  },
}));

export default function ReviewAuthorActions({ mode, setMode, saving, isAdmin, isReviewAuthor }) {
  //modes = read / edit / saving /confirmDelete
  const classes = useStyles();

  const readMode = (
    <>
      {(isReviewAuthor || !isAdmin) && (
        <CreateIcon
          className={classes.actionButtons}
          size="medium"
          onClick={() => {
            setMode('edit');
          }}
        />
      )}
      <DeleteIcon
        className={classes.actionButtons}
        size="medium"
        onClick={() => {
          setMode('confirmDelete');
        }}
      />
    </>
  );
  const editMode = (
    <>
      <SaveIcon
        className={classes.actionButtons}
        size="medium"
        onClick={() => {
          setMode('saving');
        }}
      />
      <ClearIcon
        className={classes.actionButtons}
        size="medium"
        onClick={() => {
          setMode('read');
        }}
      />
    </>
  );
  const savingSpinner = <CircularProgress className={classes.saveProgress} size={18} color="inherit" />;
  const confirmDelete = (
    <div className={classes.deleteConfirmation}>
      Delete your review, are you sure?
      <div>
        <DeleteIcon
          className={classes.actionButtons}
          size="medium"
          onClick={() => {
            setMode('deleting');
          }}
        />
        <ClearIcon
          className={classes.actionButtons}
          size="medium"
          onClick={() => {
            setMode('read');
          }}
        />
      </div>
    </div>
  );

  return (
    <div className={classes.reviewActions}>
      {mode === 'read' && readMode}
      {mode === 'edit' && editMode}
      {saving && savingSpinner}
      {mode === 'confirmDelete' && confirmDelete}
    </div>
  );
}

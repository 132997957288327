import React, { useState } from 'react';
import { Fab, useMediaQuery } from '@material-ui/core';
import { Share } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';

export default function ShareUrlBtn({ shortURL, style, className }) {
  // const classes = useStyles();
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 3000);
  };

  return (
    <Tooltip
      title={openTooltip ? 'Copied Store URL' : shortURL ? 'Copy Short Url' : 'Share URL'}
      bottom="true"
      arrow
      open={useMediaQuery('(max-width:768px)') ? openTooltip : undefined}
    >
      <CopyToClipboard
        className={className}
        style={shortURL && { marginRight: 0 }}
        text={shortURL || window.location.href}
      >
        <Fab color="default" size="small" aria-label="share" onClick={handleTooltipOpen}>
          <Share />
        </Fab>
      </CopyToClipboard>
    </Tooltip>
  );
}

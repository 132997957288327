import React from 'react';
import { Typography } from '@material-ui/core'; //Button
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import ReviewAuthorActions from './ReviewAuthorActions';
import ReviewEditForm from './ReviewEditForm';

export default function ReviewPost({
  classes,
  review,
  isReviewAuthor,
  modifiedReview,
  setModifiedReview,
  reviewMode,
  setReviewMode,
  saving,
  isAdmin,
}) {
  const daysSinceCreated = moment.utc(review.createDateTime).local().fromNow(); // 'x days/month/years ago...since created';
  //read / edit / saving /confirmDelete - set in reviewActions

  //=================== Render Modes ===================
  const readReview = (
    /* Show the review in read mode for all but edit mode */
    <>
      <div className={classes.ratingContainer}>
        <Rating
          name="read-only"
          value={review.rating ? Number(review.rating) : 0}
          precision={0.5}
          size="small"
          className={classes.ratingStars}
          readOnly
        />
        <Typography className={classes.ratingNumbers}>{review.rating}</Typography>
      </div>
      <div>
        <Typography className={classes.title}>{review.title}</Typography>
        <Typography className={classes.description}>{review.description}</Typography>
      </div>
    </>
  );
  //=================== Render  ===================
  return (
    <section className="reviewPost">
      <div className={classes.reviewItemHeader}>
        <Typography className={classes.reviewerName}>{review.reviewerFirstName}</Typography>
        <Typography className={classes.daysSinceCreated}>{daysSinceCreated}</Typography>
      </div>
      {reviewMode !== 'edit' && readReview}
      {reviewMode === 'edit' && (
        <ReviewEditForm classes={classes} setReviewForm={setModifiedReview} reviewForm={modifiedReview} />
      )}

      {(isAdmin || isReviewAuthor) && (
        <ReviewAuthorActions
          mode={reviewMode}
          setMode={setReviewMode}
          saving={saving}
          isAdmin={isAdmin}
          isReviewAuthor={isReviewAuthor}
        />
      )}
    </section>
  );
}

/* Rendering is based on parent - must be reviewAuthor
- Actions allow Edit or Delete
- EDIT MODE - will change review section to textarea / boxes allowing the user to edit directly in the list
  - Save and cancel buttons when in edit mode
- DELETE MODE - will confirm and delete the users' review

*/
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  reviewActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
  },
  saveProgress: { marginBottom: '5px', marginTop: '0px', marginRight: '5px' },
  actionButtons: {
    width: 24,
    height: 24,
    padding: '6px',
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'lightgray',
      borderRadius: '100%',
      transition: 'all .2s ease-in-out',
      cursor: 'pointer',
    },
  },
  deleteConfirmation: {
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      display: 'flex',
      alignSelf: 'flex-end',
      gap: '16px',
    },
  },
  replyButton: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: '10px',
    color: 'white',
    width: 'fit-content',
    height: '20px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
  },
}));

export default function ItemOwnerActions({
  mode,
  setMode,
  saving,
  review,
  modifiedReview,
  setModifiedReview,
  isAdmin,
}) {
  //modes = read / edit / saving /confirmDelete
  const classes = useStyles();

  const replyButton = (
    <Button
      className={classes.replyButton}
      aria-label="Reply to Review"
      onClick={() => {
        setMode('edit');
      }}
    >
      Reply
    </Button>
  );

  const readMode = (
    <>
      {!isAdmin && (
        <CreateIcon
          className={classes.actionButtons}
          size="medium"
          onClick={() => {
            setMode('edit');
          }}
        />
      )}
      <DeleteIcon
        className={classes.actionButtons}
        size="medium"
        onClick={() => {
          setMode('confirmDelete');
        }}
      />
    </>
  );
  const editMode = (
    <>
      <SaveIcon
        className={classes.actionButtons}
        size="medium"
        onClick={() => {
          setMode('saving');
        }}
      />
      <ClearIcon
        className={classes.actionButtons}
        size="medium"
        onClick={() => {
          setMode('read');
        }}
      />
    </>
  );
  const savingSpinner = <CircularProgress className={classes.saveProgress} size={18} color="inherit" />;
  const confirmDelete = (
    <div className={classes.deleteConfirmation}>
      Delete your reply, are you sure?
      <div>
        <DeleteIcon
          className={classes.actionButtons}
          size="medium"
          onClick={() => {
            //Update modified Review with no reply reply: ""
            setModifiedReview({ ...modifiedReview, reply: '' });
            setMode('deleting');
          }}
        />
        <ClearIcon
          className={classes.actionButtons}
          size="medium"
          onClick={() => {
            setMode('read');
          }}
        />
      </div>
    </div>
  );

  const hasReply = !!review.reply;
  return (
    <>
      <div className={classes.reviewActions}>
        {hasReply === false && mode === 'read' && !isAdmin && replyButton}
        {hasReply && mode === 'read' && readMode}

        {mode === 'edit' && editMode}
        {saving && savingSpinner}
        {mode === 'confirmDelete' && hasReply && confirmDelete}
      </div>
    </>
  );
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, TextField, Button } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReviewTermsPopup from './ReviewTermsPopup';
import LoginSignUpBtns from '../../components/common/LoginSignUpBtn';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 2, 2, 2),
    padding: theme.spacing(4),
    border: '1px solid #ebebeb',
    borderRadius: 5,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: theme.spacing(1),
    },
  },
  header: { fontWeight: 'bold' },
  reviewForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratingForm: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  ratingError: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(2),
  },
  reviewInput: {
    marginTop: theme.spacing(3),
  },
  errorTextLength: {
    color: theme.palette.error.main,
  },
  errTerms: {
    color: theme.palette.error.main,
    display: 'flex',
    justifyContent: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    width: '100%',
    maxWidth: '745px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
  termsButtons: {
    marginLeft: '40%',
  },
  cancelButton: {
    color: theme.palette.error,
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
  bulletpoint: {
    margin: theme.spacing(1, 2, 1, 3),
  },
  title: {
    margin: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '18pt',
  },
}));

function ReviewForm(props) {
  const {
    type,
    rating,
    setRating,
    title,
    setTitle,
    description,
    setDescription,
    submit,
    saving,
    saved,
    loggedIn,
    disableReviews,
    // focusReviewForm
  } = props;

  const classes = useStyles();

  const [invalidTitle, setInvalidTitle] = useState(false);
  const [invalidDesc, setInvalidDesc] = useState(false);
  const [invalidRating, setInvalidRating] = useState(false);
  const [invalidTerms, setInvalidTerms] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [rated, setRated] = useState(false);

  const validate = () => {
    if (title !== '' && description !== '' && rated && termsAccepted) {
      submit();
    }

    if (title === '') {
      setInvalidTitle(true);
    }

    if (description === '') {
      setInvalidDesc(true);
    }

    if (!rated) {
      setInvalidRating(true);
    }

    if (!termsAccepted) {
      setInvalidTerms(true);
    }
  };

  const disabledForm = (
    <div>
      <Typography variant="body1" className={classes.header}>
        Reviews have been disabled,
      </Typography>
      <Typography variant="body1" className={classes.header}>
        Please check back later
      </Typography>
    </div>
  );

  const loginPrompt = (
    <div>
      <Typography variant="body1" className={classes.header}>
        Login or Sign Up to leave a review.
      </Typography>
      <LoginSignUpBtns />
    </div>
  );

  const reviewPrompt = (entityType) => {
    let message = '';
    switch (entityType) {
      case 'product':
        message = 'Tried this product before? Leave a review.';
        break;
      case 'store':
        message = 'Been here before? Leave a review.';
        break;
      case 'brand':
        message = 'Have you used their products? Leave a review.';
        break;
      default:
        message = 'Leave a review.';
    }
    return (
      <>
        <Typography variant="body1" className={classes.header}>
          {message}
        </Typography>
        <hr />
      </>
    );
  };

  const reviewFields = (
    <>
      <div className={classes.reviewForm}>
        <div className={classes.ratingForm}>
          <Rating
            disabled={saved || !loggedIn}
            name="user-store-rating"
            value={rating}
            precision={0.5}
            size="large"
            className={classes.reviewInput}
            onChange={(event, newValue) => {
              setRated(true);
              setRating(newValue);
            }}
          />
          {invalidRating && (
            <Typography variant="body2" className={classes.ratingError}>
              Add a rating
            </Typography>
          )}
        </div>
        <TextField
          // autoFocus={focusReviewForm}
          disabled={saved || !loggedIn}
          variant="outlined"
          id="name-input"
          label="Title"
          className={classes.reviewInput}
          value={title}
          error={invalidTitle}
          helperText={invalidTitle ? 'Please enter a title' : ''}
          onChange={(e) => {
            setInvalidTitle(false);
            setTitle(e.target.value.slice(0, 100));
          }}
        />
        <TextField
          disabled={saved || !loggedIn}
          variant="outlined"
          id="review-input"
          label="Review"
          multiline
          rows="4"
          className={classes.reviewInput}
          error={invalidDesc}
          helperText={invalidDesc ? 'Please enter a review' : ''}
          value={description}
          onChange={(e) => {
            setInvalidDesc(false);
            setDescription(e.target.value.slice(0, 500));
          }}
        />
        <Typography
          align="right"
          variant="caption"
          className={description.length === 500 ? classes.errorTextLength : ''}
        >
          {500 - description.length} Characters Remaining
        </Typography>

        <div className={classes.terms}>
          <ReviewTermsPopup
            disabled={saved || !loggedIn}
            termsAccepted={termsAccepted}
            setTermsAccepted={(val) => {
              setInvalidTerms(false);
              setTermsAccepted(val);
            }}
          ></ReviewTermsPopup>
        </div>
        {!termsAccepted && invalidTerms && (
          <div className={classes.errTerms}>Please read and accept our review policy</div>
        )}

        <Button
          disabled={saved || !loggedIn}
          variant="contained"
          color="primary"
          className={classes.reviewInput}
          onClick={() => validate()}
        >
          {saving && !saved && <CircularProgress size={24} color="inherit" />}
          {!saving && !saved && 'Submit'}
          {saved && 'Submitted'}
        </Button>
      </div>
    </>
  );

  return (
    <div className={classes.root}>
      {disableReviews && disabledForm}
      {!disableReviews && !saved && reviewPrompt(type)}
      {!disableReviews && !loggedIn && loginPrompt}
      {!disableReviews && loggedIn && reviewFields}
    </div>
  );
}

export default ReviewForm;

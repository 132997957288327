import React, { useState, useEffect, useRef, Fragment, useContext } from 'react';
// import { withRouter } from "react-router-dom";
import StoreDetails from '../components/StoreDetails';
// import ProductList from '../components/ProductList';
import ReviewsSection from '../features/Reviews/ReviewsSection';
import LoadingError from '../components/LoadingError';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
// import SkeletonSimpleCard from '../components/SkeletonSimpleCard';
// import SimpleCard from '../components/SimpleCard';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '../components/TwitterIcon';
import { Helmet } from 'react-helmet';
import api from '../utils/api';
import {
  getCurrentWeekDay,
  parseHoursObject,
  // parseIdFromLink,
  openStatus,
  formatPhoneNumber,
  createOpenHoursString,
} from '../utils/helpers';

// import { NAV_OPTIONS, FEATURED_TYPES, STATUS } from '../utils/constants';

import './styles/StoreDetailsView.css';
import DescriptionBox from '../components/DescriptionBox';
import PreviewContext from '../PreviewContext';
// import StaticMap from "../components/StaticMap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: '1em',
    maxWidth: 1200,
    color: '#000000',
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  tabRoot: {},
  indicator: {
    position: 'absolute',
    height: 4,
    top: 0,
    transition: 'none',
  },
  details: {},
  productList: {},
  midTabBar: {
    zIndex: 98,
    boxShadow: '0px 0px',
    backgroundColor: 'transparent',
  },
  storeTab: {
    minWidth: 40,
    color: theme.palette.common.black,
    backgroundColor: '#ebebeb',
    // margin: theme.spacing(0, 1, 0, 0),
    border: '1px solid #e0e0e0',
    fontWeight: '700',
    fontSize: '14pt',
    zIndex: 90,
    // paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  storeTabSelected: {
    minWidth: 40,
    color: theme.palette.secondary.main,
    fontWeight: '700',
    // margin: theme.spacing(0, 1, 0, 0),
    zIndex: 92,
    border: '1px solid #e0e0e0',
    borderTop: '0px',
    borderBottom: '1px solid #ffffff',
    fontSize: '14pt',
    // paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },
  panelContainer: {
    border: '1px solid #e0e0e0',
    zIndex: 91,
    padding: theme.spacing(2),
    marginTop: -1,
    // marginRight: theme.spacing(1),
    marginBottom: theme.spacing(10),
  },
  detailsTab: {
    display: 'flex',
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  detailsText: {
    width: '60%',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  detailsCard: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dealGrid: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(10),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  detailsTable: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  detailsTableCell: {
    borderBottom: 'none',
    verticalAlign: 'top',
    padding: 8,
    wordWrap: 'anywhere',
  },
  hoursTableCell: {
    borderBottom: 'none',
    verticalAlign: 'top',
    padding: 2,
  },
  boldCell: {
    fontWeight: 'bold',
  },
  detailedPara: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 'bold',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  body: {
    wordBreak: 'break-all',
  },
  readMore: {
    margin: theme.spacing(1, 0, 1, 0),
    alignSelf: 'flex-end',
  },
  dealsTab: {
    margin: theme.spacing(2),
  },
  reviewsTab: {
    margin: theme.spacing(2),
  },
  reviewsFlexBox: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  reviews: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  leaveReview: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  reviewForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  reviewInput: {
    marginTop: theme.spacing(4),
  },
  internetMedia: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  mediaIcon: {
    color: theme.palette.grey.A700,
    marginRight: theme.spacing(1),
  },
}));

const getStore = async (id) => {
  return api
    .getStoreDetailedProjection(id)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

function DetailsTab(props) {
  const { store /*, mobile*/ } = props;

  const classes = useStyles();

  const parsedHours = parseHoursObject(store.hours);

  const currentWeekDay = getCurrentWeekDay();

  return (
    <div className={classes.detailsTab}>
      <div className={classes.detailsText}>
        {/* {mobile &&  */}
        {/* <Typography variant="h5">Location</Typography>
        <StaticMap address={store.address} storeName={store.name}></StaticMap> */}
        {/* <div className={classes.detailedPara}> */}
        <Typography variant="h5" className={classes.title}>
          About Us
        </Typography>
        <DescriptionBox text={store.aboutUs}></DescriptionBox>
        {/* </div> */}
        {store.firstCustomerSpecial && (
          <div className={classes.detailedPara}>
            <Typography variant="h5" className={classes.title}>
              How to Order
            </Typography>
            <DescriptionBox text={store.firstCustomerSpecial}></DescriptionBox>
          </div>
        )}
        {store.announcement && (
          <div className={classes.detailedPara}>
            <Typography variant="h5" className={classes.title}>
              Clinic Offerings
            </Typography>
            <DescriptionBox text={store.announcement}></DescriptionBox>
          </div>
        )}
        {store.minimumAge && (
          <Fragment>
            <Typography variant="h6" className={classes.title}>
              Minimum Age
            </Typography>
            <Typography variant="body2" className={classes.body}>
              {store.minimumAge}
            </Typography>
          </Fragment>
        )}
        <Typography variant="h6" className={classes.title}>
          Accepted Payment Methods
        </Typography>
        <Typography variant="body2" className={classes.body}>
          {store.paymentOptions.join(', ')}
        </Typography>
      </div>
      <div className={classes.detailsCard}>
        <TableContainer component={Paper}>
          <Table className={classes.detailsTable}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.detailsTableCell} align="center">
                  <LocationOnIcon></LocationOnIcon>
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  {`${store.address.street ? store.address.street + ', ' : ''}${store.address.city}, ${
                    store.address.province
                  }${store.address.postalCode ? ', ' + store.address.postalCode : ''}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.detailsTableCell} align="center">
                  <PhoneIcon></PhoneIcon>
                </TableCell>
                <TableCell className={classes.detailsTableCell}>{formatPhoneNumber(store.phone)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.detailsTableCell} align="center">
                  <QueryBuilderIcon></QueryBuilderIcon>
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  <Table>
                    <TableBody>
                      {parsedHours.map((day) => {
                        let boldClass = '';

                        if (day.day === currentWeekDay) {
                          boldClass = boldClass + `${classes.boldCell}`;
                        }
                        return (
                          <TableRow key={day.day}>
                            <TableCell className={`${classes.hoursTableCell} ${boldClass}`}>{day.day}</TableCell>
                            <TableCell className={`${classes.hoursTableCell} ${boldClass}`}>
                              {createOpenHoursString(day)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.detailsTableCell} align="center">
                  <EmailIcon></EmailIcon>
                </TableCell>
                <TableCell className={classes.detailsTableCell}>{store.email}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className={classes.internetMedia}>
            {store.website && (
              <IconButton className={classes.mediaIcon} href={'//' + store.website} target="_blank">
                <LanguageIcon />
              </IconButton>
            )}
            {store.facebook && (
              <IconButton className={classes.mediaIcon} href={'//' + store.facebook} target="_blank">
                <FacebookIcon />
              </IconButton>
            )}
            {store.instagram && (
              <IconButton
                className={classes.mediaIcon}
                href={
                  store.instagram[0] === '@'
                    ? `https://www.instagram.com/${store.instagram.slice(1, store.instagram.length)}`
                    : '//' + store.instagram
                }
                target="_blank"
              >
                <InstagramIcon />
              </IconButton>
            )}
            {store.snapchat && (
              <IconButton
                className={classes.mediaIcon}
                href={
                  store.snapchat[0] === '@'
                    ? `https://www.snapchat.com/add/${store.snapchat.slice(1, store.snapchat.length)}`
                    : '//' + store.snapchat
                }
                target="_blank"
              >
                {/* <CameraEnhanceIcon /> */}
                <img src={require(`../assets/logos/iconSnapchat.svg`)} alt={`snapchat-main-logo`} height="30" />
              </IconButton>
            )}
            {store.twitter && (
              <IconButton
                className={classes.mediaIcon}
                href={
                  store.twitter[0] === '@'
                    ? `https://x.com/${store.twitter.slice(1, store.twitter.length)}`
                    : '//' + store.twitter
                }
                target="_blank"
              >
                <TwitterIcon />
              </IconButton>
            )}
          </div>
        </TableContainer>
      </div>
    </div>
  );
}

function ClinicDetailsView(props) {
  const { /*productList, reviews,*/ history, previewMode } = props;

  const selectedCategory = {};
  const selectedBrands = [];

  const preview = useContext(PreviewContext);

  const mobile = useMediaQuery('(max-width:425px)');

  if (props.location.query) {
    selectedCategory.category = props.location.query.category;
    selectedCategory.parentCategory = props.location.query.parentCategory;
  } else if (preview.state.query.category) {
    selectedCategory.category = preview.state.query.category;
    selectedCategory.parentCategory = preview.state.query.parentCategory;
  }

  if (props.location.query && props.location.query.brand) {
    selectedBrands.push(props.location.query.brand);
  }

  const storeData = useRef({});

  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  // const [productList, setProductList] = useState([]);

  // const [productLoading, setProductLoading] = useState(true);
  // const [allProductsLoaded, setAllProductsLoaded] = useState(false);

  // const [productLoadingError, setProductLoadingError] = useState(false);
  const [open, setOpen] = useState(false);

  const storeId = !preview.state.screen ? props.match.params.id : preview.state.storeId;

  const storeSlug = props.match.params.storeSlug;
  const province = props.match.params.province;
  const city = props.match.params.city;

  useEffect(() => {
    let mounted = true;
    let cancelRequest;
    getStore(storeId)
      .then((res) => {
        //if the store is not published we redirect to either /deliveries or /stores depending on the storetype
        if (res.published || previewMode) {
          if (res.hours === null) {
            res.hours = {
              mondayOpen: null,
              mondayClose: null,
              tuesdayOpen: null,
              tuesdayClose: null,
              wednesdayOpen: null,
              wednesdayClose: null,
              thursdayOpen: null,
              thursdayClose: null,
              fridayOpen: null,
              fridayClose: null,
              saturdayOpen: null,
              saturdayClose: null,
              sundayOpen: null,
              sundayClose: null,
            };
          }
          storeData.current = res;
          // if (!previewMode) {
          //   // ao: sdaw-1162 - redirect to the correct province name
          //   const urlProv = window.location.href.split('/')[4] && window.location.href.split('/')[4].toLowerCase();
          //   const urlMailouts = window.location.href.split('/')[3] && window.location.href.split('/')[3];
          //   if (
          //     urlMailouts !== 'mailouts' &&
          //     urlProv !== storeData.current.address.province.replace(/ /gi, '-').toLowerCase()
          //   ) {
          //     storeData.current.storeType === 'Delivery'
          //       ? (window.location.href = `/deliveries/${storeData.current.address.province
          //           .replace(/ /gi, '-')
          //           .toLowerCase()}/${city.replace(/ /gi, '-').toLowerCase()}/${storeId}/${storeSlug}`)
          //       : (window.location.href = `/clinics/${storeData.current.address.province
          //           .replace(/ /gi, '-')
          //           .toLowerCase()}/${city.replace(/ /gi, '-').toLowerCase()}/${storeId}/${storeSlug}`);
          //   }
          // }
          setLoading(false);

          if (mounted) {
            setOpen(openStatus(res.hours));
            setLoading(false);
          }
        } else {
          res.storeType === 'Delivery' ? window.location.assign('/deliveries') : window.location.assign('/stores');
        }
      })
      .catch((err) => {
        //SDAW1001Temp 404 fix for stores that are deleted
        window.location.assign('/');
        console.log('get store error:', err);
        setLoadingError(true);
      });

    return () => {
      mounted = false;
      if (cancelRequest) cancelRequest();
    };
  }, [storeId, previewMode, city, storeSlug, province]);

  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const jumpToReview = () => {
    setValue(1);
  };
  // const currCity = props?.match?.params?.city?.replace(/^\w/, (c) => c.toUpperCase());
  const currCity = storeData?.current?.address?.city;

  const clinicMeta = {
    title: `${storeData?.current?.name || ''} ${currCity || ''} Medical Cannabis Clinic | S7DAW `,
    description: `${storeData?.current?.name} in ${currCity} specializes in a variety of medical cannabis services. Learn more and read community reviews today.`,
    h1: ` ${currCity || ''} Medical Cannabis Clinic - ${storeData?.current?.name || ''}`,
    // h1: `${storeData?.current?.name || ''}, ${currCity || ''}, Medical Cannabis Clinic`,
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{clinicMeta.title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={clinicMeta.description} />
      </Helmet>
      <Typography variant="h6" component="h1" className={classes.pageTitle}>
        {clinicMeta.h1}
      </Typography>
      {loading && !loadingError && (
        <Typography style={{ marginTop: '20px', alignSelf: 'center' }}>Loading...</Typography>
      )}
      {loadingError && <LoadingError onClick={() => history.push('/storefronts')} browse="Stores"></LoadingError>}
      {!loading && !loadingError && (
        <Fragment>
          <StoreDetails
            className={classes.details}
            storeDetails={storeData.current}
            open={open}
            loading={loading}
            jumpToReview={() => jumpToReview()}
            mobile={mobile}
          ></StoreDetails>
          <AppBar position="static" className={classes.midTabBar}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="tabs"
              variant="fullWidth"
              classes={{ indicator: classes.indicator, root: classes.tabRoot }}
            >
              <Tab
                label="Details"
                id={`tab-details`}
                aria-controls={`tabpanel-details`}
                className={value === 0 ? classes.storeTabSelected : classes.storeTab}
              />
              <Tab
                label="Reviews"
                id={`tab-deals`}
                aria-controls={`tabpanel-deals`}
                className={value === 1 ? classes.storeTabSelected : classes.storeTab}
              />
            </Tabs>
          </AppBar>
          <div className={classes.panelContainer}>
            {value === 0 && <DetailsTab mobile={mobile} store={storeData.current}></DetailsTab>}

            {value === 1 && (
              <Fragment>
                {!preview.state.screen && (
                  <ReviewsSection
                    type="store"
                    // reviews={reviews}
                    itemId={storeId}
                    ownerName={storeData.current.name}
                    disableReviews={storeData.current.disableReviews}
                    // focusReviewForm={focusReviewForm}
                  />
                )}
                {preview.state.screen && <Typography>Not available in preview mode.</Typography>}
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default /*withRouter(*/ ClinicDetailsView /*)*/;

import React from 'react';

import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import UpdateIcon from '@material-ui/icons/Update';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: (theme.spacing(0.75), 0),
    color: theme.palette.grey['600'],
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.70rem',
      justifyContent: 'flex-start',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function LastUpdated(props) {
  const classes = useStyles();

  const { time } = props;

  const days = time ? moment.utc(time).local().fromNow() : '--';

  return (
    <Typography variant="caption" className={classes.root}>
      <UpdateIcon color="secondary" className={classes.icon} fontSize="small" />
      Last Updated {days}
    </Typography>
  );
}

import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ProductListItem from './ProductListItem';
import {
  Typography,
  List,
  ListItem,
  TablePagination,
  useMediaQuery,
  Button,
  Modal,
  Fade,
  Backdrop,
} from '@material-ui/core';
import SearchBar from './SearchBar';
import { includes, parseIdFromLink } from '../utils/helpers';
import { sortOptions, filterTypes, ALL_CATEGORIES, NONE, priceRanges, PLANT_TYPES } from '../utils/constants';
import DropDownSelect from './DropDownSelect';
import FiltersList from './FiltersList';
import { FilterList } from '@material-ui/icons';
import MobileFiltersList from './MobileFiltersList';
import { useStateValue } from '../state';

//For sticky mobile filter button
import './styles/ProductList.css';
import LastUpdated from './LastUpdated';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 0px 0px 0px',
  },
  filters: {
    margin: '0px 25px 0px 0px',
    width: 350,
  },
  productList: {
    width: 800,
    display: 'flex',
    flexDirection: 'column',
  },
  listControls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    height: '3rem', //Used to prevent Content shift as menu loads
  },
  listItem: {
    minWidth: '100%',
    padding: 0,
    marginBottom: '1rem',
  },
  searchBar: {
    width: '35%',
  },
  sortDropDown: {
    width: '35%',
  },
  mobileFilterButton: {
    marginTop: theme.spacing(0.5),
    width: '100%',
  },
  filterButton: {
    // textTransform: "none",
    bottom: theme.spacing(3),
    fontSize: '12pt',
    fontWeight: 'bold',
    // position: "-webkit-sticky",
    // position: "sticky",
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: '96',
    width: 130,
    height: 50,
  },
  filterIcon: {
    marginRight: theme.spacing(1.5),
  },
  categoryTitle: {
    color: theme.palette.primary.main,
    fontWeight: '600',
    fontSize: '18pt',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paginationControls: {
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  spacer: {
    display: 'none',
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  selectRoot: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  actions: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
    },
  },
}));

function ProductList(props) {
  const classes = useStyles();

  const {
    productList,
    selectedCategory,
    selectedBrands,
    brand,
    sortParam,
    storeId,
    brandId,
    storeSlug,
    allProductsLoaded,
  } = props;

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState(
    sortParam ? sortOptions.find((option) => option.field === sortParam) : sortOptions[0]
  );
  const [page, setPage] = useState(0);
  const [rowsPer, setRowsPer] = useState(10);
  const [productCount, setProductCount] = useState(productList.length);
  const [mobileFilters, setMobileFilters] = useState(false);
  const [{ location }] = useStateValue();

  const province = location.province.replace(/\s+/g, '-').toLowerCase();

  const city = location.city.replace(/\s+/g, '-').toLowerCase();

  const toggleMobileFilters = () => {
    setMobileFilters(!mobileFilters);
  };

  const [filteredProducts, setFilteredProducts] = useState(productList);

  const genetics = {};

  PLANT_TYPES.forEach((type) => (genetics[`${type}`] = false));

  const [selected, setSelected] = useState({
    categoryFilter: {
      category: selectedCategory.category ? selectedCategory.category : ALL_CATEGORIES,
      parent: selectedCategory.parentCategory ? selectedCategory.parentCategory : NONE,
    },
    genetics,
    priceRange: 'All',
    flag: '',
    brands: selectedBrands ? selectedBrands : [],
  });

  const changeFilter = (type, value) => {
    switch (type) {
      case filterTypes.CATEGORY:
        setSelected({
          ...selected,
          categoryFilter: { category: value.category, parent: value.parent },
        });
        break;

      case filterTypes.GENETICS:
        setSelected({
          ...selected,
          genetics: {
            ...selected.genetics,
            [`${value}`]: !selected.genetics[`${value}`] ? !selected.genetics[`${value}`] : false,
          },
        });
        break;

      case filterTypes.PRICE_RANGE:
        setSelected({
          ...selected,
          priceRange: value,
        });
        break;

      case filterTypes.FLAGS:
        setSelected({
          ...selected,
          flag: value,
        });
        break;

      case filterTypes.BRANDS:
        setSelected({
          ...selected,
          brands: selected.brands.find((brand) => brand.id === value.id)
            ? selected.brands.filter((brand) => brand.id !== value.id)
            : [...selected.brands, value],
        });
        break;

      case filterTypes.CLEAR:
        const genetics = {};

        PLANT_TYPES.forEach((type) => (genetics[`${type}`] = false));

        const resetFilters = {
          categoryFilter: { category: ALL_CATEGORIES, parent: NONE },
          genetics,
          priceRange: 'All',
          flag: '',
          brands: [],
        };

        setSelected({
          ...resetFilters,
        });
        break;

      default:
        console.log('ERROR: Something went wrong when setting filters');
    }
  };

  const matchesCategory = useCallback(
    (categories) => {
      if (selected.categoryFilter.category === ALL_CATEGORIES) return true;

      return (
        (categories.category === selected.categoryFilter.category && selected.categoryFilter.parent === NONE) ||
        (categories.subcategory === selected.categoryFilter.category &&
          categories.category === selected.categoryFilter.parent) ||
        (categories.subsubcategory === selected.categoryFilter.category &&
          categories.subcategory === selected.categoryFilter.parent)
      );
    },
    [selected]
  );

  const [latestUpdate, setLatestUpdate] = useState('');

  useEffect(() => {
    let latest = '';

    productList.forEach((product) => {
      if (latest === '') {
        latest = product.updateDateTime;
      } else if (moment(product.updateDateTime).isAfter(moment(latest))) {
        latest = product.updateDateTime;
      }

      setLatestUpdate(latest);
    });
  }, [productList]);

  //Filter products based on search and filter values
  useEffect(() => {
    //Search and category filter
    let filtered = productList
      .filter((product) => includes(product.name, search) || product.tags.some((tag) => includes(tag, search)))
      .filter((product) => matchesCategory(product.categories));

    //Price range filter
    const low = priceRanges.filter((range) => range.name === selected.priceRange)[0].low;
    const high = priceRanges.filter((range) => range.name === selected.priceRange)[0].high;

    filtered = brand
      ? filtered
      : filtered.filter(
          (product) =>
            (product.productPricingList &&
              product.productPricingList.filter((listing) => {
                return listing.price >= low && listing.price < high;
              }).length >= 1) ||
            (product.productCustomizations.find(
              (customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
            ) &&
              product.productCustomizations
                .find(
                  (customization) =>
                    parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
                )
                .productPricingList.filter((listing) => {
                  return listing.price >= low && listing.price < high;
                }).length >= 1)
        );

    //Genetics filter
    if (selected.genetics) {
      if (!Object.keys(selected.genetics).every((genetic) => !selected.genetics[`${genetic}`])) {
        const geneticFiltered = [];

        Object.keys(selected.genetics).forEach((genetic) => {
          filtered.forEach((product) => {
            if (product.genetics === genetic && selected.genetics[`${genetic}`]) geneticFiltered.push(product);
          });
        });

        filtered = geneticFiltered;
      }
    }

    //Flags filter
    if (selected.flag) {
      const flagFiltered = [];

      filtered.forEach((product) => {
        if (product.flags && product.flags[0] === selected.flag) flagFiltered.push(product);
      });

      filtered = flagFiltered;
    }

    if (selected.brands.length > 0) {
      const brandFiltered = [];

      filtered.forEach((product) => {
        if (
          product.brand &&
          selected.brands.map((brand) => brand.id).includes(product.brand._links.self.href.replace('{?projection}', ''))
        ) {
          brandFiltered.push(product);
        }
      });

      filtered = brandFiltered;
    }

    setFilteredProducts(filtered);
  }, [search, selected, productList, matchesCategory, brand, storeId]);

  const updateSearch = (value) => {
    setSearch(value);
  };

  const updateRowsPer = useCallback(
    (value) => {
      if (value * page + 1 > productCount) {
        setPage(Math.floor(productCount / value));
      }
      setRowsPer(value);
    },
    [page, productCount]
  );

  const changePage = (page) => {
    //scroll to top of page when changing page, smooth scroll
    window.scrollTo({ behavior: 'smooth', top: 0 });

    setPage(page);
  };

  const changeSort = (sort) => {
    setPage(0);
    setSort(sort);
  };

  useEffect(() => {
    setProductCount(filteredProducts.length);
    updateRowsPer(rowsPer);
  }, [updateRowsPer, rowsPer, filteredProducts]);

  //List of distinct top categories to be used in grouping products and rendering category title. If the sort is not the default, then all products are listed under the category "All Products"
  const categoryList =
    sort.name === sortOptions[0].name
      ? [
          ...new Set(
            filteredProducts
              // .filter(product => includes(product.name, search))
              .map((product) => product.categories.category)
              .sort((first, second) =>
                first === 'Flower' ? -1 : second === 'Flower' ? 1 : first.localeCompare(second)
              )
          ),
        ]
      : [selected.categoryFilter.category];

  const startIndex = page * rowsPer;
  const endIndex = startIndex + rowsPer - 1;
  let productCounter = 0;
  let overallIndex = 0;

  const desktop = useMediaQuery('(min-width:1024px)');
  const mobile = useMediaQuery('(max-width:767px)');

  const parsedProductList = categoryList.map((category, index) => {
    let categoryProducts = 0;
    if (productCounter < rowsPer) {
      return filteredProducts
        .filter((product) => {
          return (
            /*In the menu, Only display when in all catories, and under their primary catatory 
          Ref Filterlist selecing a shared sub category ie edible oil, will ALL edible oils*/
            category === ALL_CATEGORIES || product.categories.category === category
          );
        })
        .sort((first, second) => {
          if (!brand && sort.field === 'price') {
            const firstPrice = first.productPricingList[0]
              ? first.productPricingList[0].price
              : first.productCustomizations.find(
                  (customization) =>
                    parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
                )[0];

            const secondPrice = second.productPricingList[0]
              ? second.productPricingList[0].price
              : second.productCustomizations.find(
                  (customization) =>
                    parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
                )[0];

            return sort.direction === 'asc'
              ? Number(firstPrice) - Number(secondPrice)
              : Number(secondPrice) - Number(firstPrice);
          } else if (sort.field === 'name') {
            return sort.direction === 'asc'
              ? first.name.localeCompare(second.name)
              : second.name.localeCompare(first.name);
          } else if (brand && sort.field === 'latest') {
            if (first.latestRank === null && second.latestRank === null) {
              return first.name.localeCompare(second.name);
            } else if (first.latestRank === null && second.latestRank !== null) {
              return 1;
            } else if (first.latestRank !== null && second.latestRank === null) {
              return -1;
            } else if (first.latestRank !== null && second.latestRank !== null) {
              return first.latestRank - second.latestRank;
            }
          } else if (brand && sort.field === 'popular') {
            if (first.popularRank === null && second.popularRank === null) {
              return first.name.localeCompare(second.name);
            } else if (first.popularRank === null && second.popularRank !== null) {
              return 1;
            } else if (first.popularRank !== null && second.popularRank === null) {
              return -1;
            } else if (first.popularRank !== null && second.popularRank !== null) {
              return first.popularRank - second.popularRank;
            }
          }
          let firstRank = brand || !first.brand ? first.rank : null;
          let secondRank = brand || !second.brand ? second.rank : null;

          if (!brand && first.brand) {
            firstRank = first.productCustomizations.find(
              (custom) => parseIdFromLink(custom.store._links.self.href, 0, '{?projection}') === storeId
            ).rank;
          }

          if (!brand && second.brand) {
            secondRank = second.productCustomizations.find(
              (custom) => parseIdFromLink(custom.store._links.self.href, 0, '{?projection}') === storeId
            ).rank;
          }

          if (firstRank === null && secondRank === null) {
            return first.name.localeCompare(second.name);
          } else if (firstRank !== null && secondRank === null) {
            return -1;
          } else if (firstRank === null && secondRank !== null) {
            return 1;
          } else {
            return firstRank - secondRank !== 0 ? firstRank - secondRank : first.name.localeCompare(second.name);
          }
        })
        .filter(() => {
          if (productCounter < rowsPer && overallIndex >= startIndex && overallIndex <= endIndex) {
            overallIndex++;
            productCounter++;
            return true;
          } else {
            overallIndex++;
            return false;
          }
        })
        .map((product, index) => {
          categoryProducts++;
          return (
            <Fragment key={index}>
              {categoryProducts === 1 && (
                <Typography gutterBottom variant="h5" className={classes.categoryTitle}>
                  {category}
                  {/* {category.toUpperCase()} */}
                </Typography>
              )}
              <ListItem className={classes.listItem}>
                <ProductListItem
                  product={product}
                  mobile={mobile}
                  storeId={brand ? '' : storeId}
                  brandId={brand ? brandId : ''}
                  storeSlug={storeSlug}
                  province={province}
                  city={city}
                ></ProductListItem>
              </ListItem>
            </Fragment>
          );
        });
    } else {
      return <Fragment key={`empty-${index}`}></Fragment>;
    }
  });

  return (
    <div className={`${classes.root}`}>
      {productList.length <= 0 && (
        <Typography style={{ marginTop: '20px' }}>This store has no products yet. Please check back later.</Typography>
      )}
      {productList.length > 0 && desktop && (
        <div className={classes.filters}>
          <FiltersList
            brand={brand}
            productList={productList}
            onFilter={(type, value) => changeFilter(type, value)}
            priceRange={selected.priceRange}
            selectedCategory={selected.categoryFilter}
            selectedGenetics={selected.genetics}
            selectedFlag={selected.flag}
            selectedBrands={selected.brands}
            storeId={storeId}
            allProductsLoaded={allProductsLoaded}
          ></FiltersList>
        </div>
      )}
      {productList.length > 0 && (
        <div className={`${classes.productList}`} id="product-list">
          {allProductsLoaded ? (
            <div className={classes.listControls}>
              <div className={classes.searchBar} id="search-bar">
                <SearchBar
                  value={search}
                  onChange={(value) => updateSearch(value)}
                  onClear={() => setSearch('')}
                ></SearchBar>
              </div>
              <div className={classes.sortDropDown} id="sort-drop-down">
                <DropDownSelect
                  value={sort.name}
                  onChange={(value) => changeSort(sortOptions.find((option) => option.name === value))}
                  options={sortOptions.filter((option) =>
                    brand ? option.field !== 'price' : option.field !== 'latest' && option.field !== 'popular'
                  )}
                ></DropDownSelect>
                <LastUpdated time={latestUpdate}></LastUpdated>
              </div>
            </div>
          ) : (
            <div className={classes.listControls} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress size="1rem" />
              <Typography style={{ alignSelf: 'center', paddingLeft: '1rem' }}>Loading more items...</Typography>
            </div>
          )}
          <List className="product-list-items">{parsedProductList}</List>
          <TablePagination
            id="product-list-pagination"
            component="nav"
            page={page}
            rowsPerPage={rowsPer}
            count={productCount}
            onChangePage={(event, page) => changePage(page)}
            onChangeRowsPerPage={(event) => updateRowsPer(event.target.value)}
            className={classes.paginationControls}
            classes={{
              spacer: classes.spacer,
              toolbar: classes.toolbar,
              selectRoot: classes.selectRoot,
              actions: classes.actions,
            }}
          />
          {!desktop && (
            <Button
              variant="contained"
              color="default"
              size="small"
              className={classes.filterButton}
              onClick={() => toggleMobileFilters()}
              id="mobile-filters-list-button"
            >
              <FilterList className={classes.filterIcon} />
              Filters
            </Button>
          )}
        </div>
      )}
      {productList.length > 0 && (
        <Modal
          className={classes.modal}
          open={mobileFilters && !desktop}
          onClose={toggleMobileFilters}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={mobileFilters}>
            <div className={classes.modal}>
              <MobileFiltersList
                brand={brand}
                productList={productList}
                onFilter={(type, value) => changeFilter(type, value)}
                priceRange={selected.priceRange}
                selectedCategory={selected.categoryFilter}
                selectedGenetics={selected.genetics}
                selectedFlag={selected.flag}
                selectedBrands={selected.brands}
                onClose={() => toggleMobileFilters()}
                storeId={storeId}
              ></MobileFiltersList>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
}

export default ProductList;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Modal, Backdrop, Fade, Button } from '@material-ui/core';
import api from '../../utils/api';
import { STATUS } from '../../utils/constants';
// import ReviewItem from './ReviewItem/Index';
import ReviewForm from './ReviewForm';
import cookies from '../../cookies';
// import { parseIdFromLink } from '../utils/helpers';
import ReviewItemIndex from './ReviewItem/ReviewItemIndex';
import { parseIdFromLink } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  reviewsTab: {
    margin: theme.spacing(2),
  },
  reviewsFlexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  reviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  reviews: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  leaveReviewCTASection: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
}));

const isUserItemOwner = async (userID, itemId, itemType) => {
  //Get owner info
  const ownerInfoRaw = await api.getEntityOwner(itemId, itemType === 'brand');
  const ownerIDLink = ownerInfoRaw.data._links.self.href;
  const ownerID = parseIdFromLink(ownerIDLink, 0, '{?projection}');
  const isOwner = ownerID === userID;
  return isOwner;
};

function ReviewsSection(props) {
  const { type, itemId, ownerName, disableReviews, productInfo } = props;
  const classes = useStyles();
  // const [editing, setEditing] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [postError, setPostError] = useState(false);

  const [isItemOwner, setIsItemOwner] = useState(false);

  const userID = cookies.getUserID();
  const loggedIn = userID && cookies.getJWT() && cookies.getRole();
  const postReview = async (data, type, itemId) => {
    return api
      .createReview(data, type, itemId, cookies.getUserID())
      .then((res) => res)
      .catch((err) => {
        if (err.message.includes('between') || err.message.includes('48')) {
          setErrorMsg(err.message);
        } else if (err.message.includes('owner')) {
          setErrorMsg(err.message);
        } else {
          setErrorMsg('An error has occured while trying to post your review');
        }
        //throw STATUS.ERROR;
      });
  };

  useEffect(() => {
    let fetchCall = null;
    switch (type) {
      case 'store':
        fetchCall = api.getStoreReviews;
        break;
      case 'brand':
        fetchCall = api.getBrandReviews;
        break;
      case 'product':
        fetchCall = api.getProductReviews;
        break;
      default:
        break;
    }

    const fetchAndSetState = async () => {
      try {
        const reviewsRes = await fetchCall(itemId);
        const sortReviewsByDate = (reviews) => {
          return reviews.sort((x, y) => {
            if (x.createDateTime > y.createDateTime) {
              return -1;
            } else if (x.createDateTime < y.createDateTime) {
              return 1;
            } else {
              return 0;
            }
          });
        };
        const sortedReviewsByDate = sortReviewsByDate(reviewsRes);

        let entityIDtoCheckIfOwner = itemId; //Default to id of store or brand
        // For products, need to check owner of the items parent (owner of store or brand), if product sent parent ID for owner check
        if (type === 'product') {
          //Product owner maybe store or brand - .stores[0] or .brand within productInfo
          const parentIDLink = productInfo.brand
            ? productInfo.brand._links.self.href
            : productInfo.stores[0]._links.self.href;
          entityIDtoCheckIfOwner = parseIdFromLink(parentIDLink, 0, '{?projection}');
        }
        const isUserOwner = await isUserItemOwner(userID, entityIDtoCheckIfOwner, type);

        setIsItemOwner(isUserOwner);
        setReviews(sortedReviewsByDate);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setStatus(STATUS.ERROR);
      }
    };

    if (fetchCall) {
      fetchAndSetState();
    }
  }, [type, itemId, userID, productInfo]);

  const submit = () => {
    //>>>>>>>>>>>>>>>>>>>>> This should be in a useEffect
    setSaving(true);
    postReview(
      {
        description,
        rating,
        title,
      },
      type,
      itemId
    )
      .then((res) => {
        setSaved(true);
        setSaving(false);
        const newReview = {
          ...res,
          reviewerFirstName: res._embedded.user.firstName,
          user: res._embedded.user,
        };
        const newReviewList = [newReview, ...reviews];
        setReviews(newReviewList);
      })
      .catch((err) => {
        setSaving(false);
        setPostError(true);
      });
  };

  const isAdmin = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole()); //-TODO - get this from state not cookies

  //========================================= Render Component ==========================================
  return (
    <div className={classes.reviewsTab}>
      <Typography variant="h4" className={classes.title}>
        Reviews
      </Typography>
      <div className={classes.reviewsFlexBox}>
        {loading && <Typography>Loading...</Typography>}
        {status === STATUS.ERROR && (
          <Typography>An error occurred when retrieving reviews. Please try again later.</Typography>
        )}
        {!loading && status === '' && (
          <div className={classes.reviews}>
            {reviews.length === 0 && <Typography variant="body1">There are no reviews yet!</Typography>}
            {reviews.length > 0 &&
              reviews.map((review) => {
                const parsedReview = {
                  ...review,
                  createdDateTime: review.createDateTime + 'Z',
                  reviewerID: review.user._links.self.href.split('/').pop().replace('{?projection}', ''),
                  replyAuthorName: ownerName, //Store or Brand Name  - even if its a product
                  replyAuthorUsername: review.user.username,
                };

                return (
                  <ReviewItemIndex
                    key={review._links.self.href}
                    review={parsedReview}
                    setReviews={setReviews}
                    setError={setStatus}
                    isItemOwner={isItemOwner}
                    isAdmin={isAdmin}
                  />
                );
              })}
          </div>
        )}
        <div className={classes.leaveReviewCTASection}>
          <ReviewForm
            // focusReviewForm={focusReviewForm}
            disableReviews={disableReviews}
            loggedIn={loggedIn}
            type={type}
            rating={rating}
            setRating={(val) => setRating(val)}
            title={title}
            setTitle={(val) => setTitle(val)}
            description={description}
            setDescription={(val) => setDescription(val)}
            //userID={cookies.getUserID()}
            submit={() => submit()}
            saving={saving}
            saved={saved}
          ></ReviewForm>
        </div>
      </div>
      <Modal
        className={classes.modal}
        open={postError}
        onClose={() => {
          setPostError(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={postError}>
          <div className={classes.modalPaper}>
            <Typography className={classes.statusMessage}>{errorMsg}</Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.statusAcceptButton}
              onClick={() => setPostError(false)}
            >
              Ok
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ReviewsSection;

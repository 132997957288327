import React from 'react';
import { TextField } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

export default function ReviewEditForm({ classes, setReviewForm, reviewForm }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReviewForm({ ...reviewForm, [name]: value });
  };

  return (
    <>
      <div className={classes.ratingContainer}>
        <Rating
          name="rating"
          precision={0.5}
          size="large"
          className={classes.reviewInput}
          onChange={handleChange}
          value={reviewForm.rating}
          //   defaultValue={review.rating ? Number(review.rating) : 0}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <TextField
          label="Review Title"
          name="title"
          variant="outlined"
          id="name-input"
          type="text"
          inputProps={{ maxLength: 100 }}
          //   defaultValue={review.title}
          onChange={handleChange}
          value={reviewForm.title}
        />
        <TextField
          label="Description"
          name="description"
          variant="outlined"
          id="review-input"
          type="text"
          multiline
          rows="4"
          inputProps={{ maxLength: 500 }}
          helperText={`${reviewForm.description.length}/500 characters`}
          //   defaultValue={review.description}
          onChange={handleChange}
          value={reviewForm.description}
        />
      </div>
    </>
  );
}

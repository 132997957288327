import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  reviewerDetails: {
    padding: theme.spacing(2, 0),
  },
  reviewerDetailsText: {
    marginTop: '6px',
    color: 'gray',
  },
}));

export default function ReviewerDetails({ review }) {
  const classes = useStyles();
  return (
    <section className={classes.reviewerDetails}>
      <Typography id={'user'} className={classes.reviewerDetailsText}>
        Reviewer Details:
      </Typography>
      <Typography id={'user'} className={classes.reviewerDetailsText}>
        {`Username: ${review.replyAuthorUsername}`}
      </Typography>
      <Typography id={'ipAddress'} className={classes.reviewerDetailsText}>
        {`IP: ${review.ipAddress ?? 'N/A'}`}
      </Typography>
    </section>
  );
}

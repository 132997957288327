import React, { useState, useEffect, Fragment, useContext } from 'react';
import { makeStyles, Button, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
// import { brandProducts, singleBrandDataWithHours } from "../utils/example-data";
import GeneralBreadcrumbs from '../components/GeneralBreadcrumbs';
import LoadingError from '../components/LoadingError';
import BrandProfile from '../components/BrandProfile';
import FeaturedList from '../components/FeaturedList';
import ProductList from '../components/ProductList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import api from '../utils/api';
import { FEATURED_TYPES, STATUS } from '../utils/constants';
import ReviewsSection from '../features/Reviews/ReviewsSection';
import PreviewContext from '../PreviewContext';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  content: {
    margin: theme.spacing(2),
    width: '100%',
    maxWidth: theme.screen.maxWidth,
  },
  lists: {
    margin: theme.spacing(2, 0, 6, 0),
  },
  productList: {
    margin: theme.spacing(2),
  },
  backButton: {
    margin: theme.spacing(2),
    fontWeight: '700',
  },
  emptyList: {
    margin: theme.spacing(2),
  },
  tabRoot: {},
  indicator: {
    position: 'absolute',
    height: 4,
    top: 0,
    transition: 'none',
  },
  tabsContainer: {
    margin: theme.spacing(2),
  },
  midTabBar: {
    zIndex: 98,
    boxShadow: '0px 0px',
    backgroundColor: 'transparent',
  },
  storeTab: {
    minWidth: 40,
    color: theme.palette.common.black,
    backgroundColor: '#ebebeb',
    // margin: theme.spacing(0, 1, 0, 0),
    border: '1px solid #e0e0e0',
    fontWeight: '700',
    fontSize: '14pt',
    zIndex: 90,
    // paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  storeTabSelected: {
    minWidth: 40,
    color: theme.palette.secondary.main,
    fontWeight: '700',
    // margin: theme.spacing(0, 1, 0, 0),
    zIndex: 92,
    border: '1px solid #e0e0e0',
    borderTop: '0px',
    borderBottom: '1px solid #ffffff',
    fontSize: '14pt',
    // paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  panelContainer: {
    border: '1px solid #e0e0e0',
    zIndex: 91,
    padding: theme.spacing(2),
    marginTop: -1,
    // marginRight: theme.spacing(1),
    marginBottom: theme.spacing(10),
  },
}));

const getBrand = async (id) => {
  return api
    .getBrandDetailedProjection(id)
    .then((res) => res.data)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getProducts = async (id) => {
  return api
    .getProductsForBrandDetailed(id)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

export default function BrandDetailsView(props) {
  const { history, previewMode } = props;

  const classes = useStyles();

  const [brand, setBrand] = useState([]);
  const [latest, setLatest] = useState([]);
  const [popular, setPopular] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [error, setError] = useState(false);

  const preview = useContext(PreviewContext);

  const [browse, setBrowse] = useState(props.location.query || preview.state.query.category ? true : false);
  const [sort, setSort] = useState('');

  const brandId = !preview.state.screen ? props.match.params.id : preview.state.brandId;

  const brandSlug = props.match.params.storeSlug;

  useEffect(() => {
    getBrand(brandId)
      .then((res) => {
        if (res.published || previewMode) {
          setBrand(res);
          setLoading(false);
        } else {
          window.location.assign('/brands');
        }
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });

    getProducts(brandId)
      .then((res) => {
        setProducts(res.filter((product) => !product.removeFromMenu).sort((x, y) => x.name.localeCompare(y.name)));

        setPopular(
          res
            .filter(
              (product) => !product.removeFromMenu && product.popularRank !== null && product.popularRank !== undefined
            )
            .sort((x, y) => x.popularRank - y.popularRank)
        );

        setLatest(
          res
            .filter(
              (product) => !product.removeFromMenu && product.latestRank !== null && product.latestRank !== undefined
            )
            .sort((x, y) => x.latestRank - y.latestRank)
        );

        setLoadingProducts(false);
      })
      .catch(() => {
        setError(true);
      });
  }, [brandId, previewMode]);

  const selectedCategory = {};

  if (props.location.query) {
    selectedCategory.category = props.location.query.category;
    selectedCategory.parentCategory = props.location.query.parentCategory;
  } else if (preview.state.query.category) {
    selectedCategory.category = preview.state.query.category;
    selectedCategory.parentCategory = preview.state.query.parentCategory;
  }

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const jumpToReview = () => {
    setValue(1);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`${brand.name ? `${brand.name}  | Cannabis Brand | ` : ''}S7DAW`}</title>
        <meta
          name="description"
          content={`View cannabis strains, products and other brand information for ${brand.name ? brand.name : 'N/A'}${
            brand.address ? ` in ${brand.address.city}, ${brand.address.province}` : ``
          } on s7daw.com.`}
        />
      </Helmet>
      <div className={classes.content}>
        {loading && !error && (
          <Typography style={{ width: '100%' }} align="center">
            Loading...
          </Typography>
        )}
        {!loading && (
          <Fragment>
            <GeneralBreadcrumbs
              preview={preview.state.screen}
              value={[{ label: 'Brands', path: '/brands' }, { label: brand.name }]}
            ></GeneralBreadcrumbs>
            <BrandProfile loading={loading} brand={brand} jumpToReview={() => jumpToReview()}></BrandProfile>
            <div className={classes.tabsContainer}>
              <AppBar position="static" className={classes.midTabBar}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label="tabs"
                  variant="fullWidth"
                  classes={{
                    indicator: classes.indicator,
                    root: classes.tabRoot,
                  }}
                >
                  <Tab
                    label="Products"
                    id={`tab-products`}
                    aria-controls={`tabpanel-products`}
                    className={value === 0 ? classes.storeTabSelected : classes.storeTab}
                  />
                  <Tab
                    label="Reviews"
                    id={`tab-reviews`}
                    aria-controls={`tabpanel-reviews`}
                    className={value === 1 ? classes.storeTabSelected : classes.storeTab}
                  />
                </Tabs>
              </AppBar>
              <div className={classes.panelContainer}>
                {value === 0 && loadingProducts && !error && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ marginTop: '20px' }}>Loading...</Typography>
                  </div>
                )}
                {value === 0 && error && (
                  <LoadingError onClick={() => history.push('/brands')} browse="Brands"></LoadingError>
                )}
                {value === 0 && !loadingProducts && !error && (
                  <Fragment>
                    {!browse && !loadingProducts && (
                      <div className={classes.lists}>
                        {latest.length > 0 && (
                          <FeaturedList
                            index="1"
                            listData={latest}
                            title="Latest Products"
                            image="latest"
                            type={FEATURED_TYPES.BRANDPRODUCT}
                            viewAllPath={'NONE'}
                            brandBrowse
                            setBrowse={() => {
                              setSort('latest');
                              setBrowse(true);
                            }}
                            handleNavBarChange={(event) => {}}
                            brandId={brandId}
                            brandSlug={brandSlug}
                          />
                        )}
                        {popular.length > 0 && (
                          <FeaturedList
                            index="2"
                            listData={popular}
                            title="Most Popular"
                            image="popular"
                            type={FEATURED_TYPES.BRANDPRODUCT}
                            viewAllPath={'NONE'}
                            brandBrowse
                            setBrowse={() => {
                              setSort('popular');
                              setBrowse(true);
                            }}
                            handleNavBarChange={(event) => {}}
                            brandId={brandId}
                            brandSlug={brandSlug}
                          />
                        )}
                        {products.length > 0 && (
                          <FeaturedList
                            index="3"
                            listData={products}
                            title="All Products"
                            // image="latest"
                            type={FEATURED_TYPES.BRANDPRODUCT}
                            viewAllPath={'NONE'}
                            brandBrowse
                            setBrowse={() => setBrowse(true)}
                            handleNavBarChange={(event) => {}}
                            brandId={brandId}
                            brandSlug={brandSlug}
                          />
                        )}
                        {products.length === 0 && (
                          <Typography variant="body2" className={classes.emptyList}>
                            This brand does not have any products available at the moment. Please check back later.
                          </Typography>
                        )}
                      </div>
                    )}
                    {browse && !loadingProducts && (
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.backButton}
                        onClick={() => {
                          setSort('');
                          setBrowse(false);
                        }}
                      >
                        <ArrowBackIcon /> Back
                      </Button>
                    )}
                    {browse && !loadingProducts && (
                      <div className={classes.productList}>
                        <ProductList
                          brand
                          brandId={brandId}
                          sortParam={sort}
                          productList={products}
                          selectedCategory={selectedCategory}
                          storeSlug={brandSlug}
                        ></ProductList>
                      </div>
                    )}
                  </Fragment>
                )}
                {value === 1 && (
                  <Fragment>
                    {!preview.state.screen && (
                      <ReviewsSection
                        type="brand"
                        itemId={brandId}
                        ownerName={brand.name}
                        disableReviews={brand.disableReviews}
                      />
                    )}
                    {preview.state.screen && <Typography>Not available in preview mode.</Typography>}
                  </Fragment>
                )}
              </div>
            </div>
          </Fragment>
        )}
        {!loading && error && <LoadingError onClick={() => history.push(`/brands`)} browse="Brands"></LoadingError>}
      </div>
    </div>
  );
}

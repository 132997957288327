import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Fab, Button, Backdrop, Modal, Fade } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { Call, Email, StarRate } from '@material-ui/icons';
import LanguageIcon from '@material-ui/icons/Language';
import api from '../utils/api';
import { makeImageKitURL, parseIdFromLink } from '../utils/helpers';
import { formatPhoneNumber, abbreviate } from '../utils/helpers';
import StaticMap from './StaticMap';
import Tooltip from '@material-ui/core/Tooltip';
import MapIcon from '@material-ui/icons/Map';
import OpenStatusBadge from './OpenStatusBadge';
import OpenStatusText from './OpenStatusText';
import ShareUrlBtn from './ShareUrlBtn';
//import theme from "../theme";

const useStyles = makeStyles((theme) => ({
  storeDetailsRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  main: {
    display: 'flex',
  },
  leftContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '66%',
    },
  },
  info: {
    display: 'flex',
  },
  rightContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: '#EBEBEB',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  rightContainerText: {
    // marginTop: theme.spacing(13),
    alignSelf: 'center',
  },
  follow: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignContent: 'center',
      justifyContent: 'center',
    },
  },

  followButton: {
    color: 'white',
    width: '100px',
    height: '25px',
    marginTop: theme.spacing(1),
    // marginLeft: theme.spacing(1)
  },

  map: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  // logoContainer: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  logo: {
    width: 180,
    height: 180,
    [theme.breakpoints.down('sm')]: {
      width: 90,
      height: 90,
    },
    objectFit: 'contain',
    border: '1px solid #ebebeb',
  },
  storeName: {
    fontWeight: 'bold',
    fontSize: '16pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  detailsContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      '& > p': {
        fontSize: '0.8rem',
      },
    },
  },
  openStatus: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      gap: '0.5rem',
      '& > p': {
        fontSize: '0.8rem',
      },
    },
  },
  rating: {
    display: 'flex',
    // [theme.breakpoints.down("sm")]: {
    //   flexDirection: "column"
    // }
  },
  stars: {
    paddingRight: 5,
    // [theme.breakpoints.down("sm")]: {
    //   marginBottom: theme.spacing(1)
    // }
  },
  open: {
    color: '#00af00',
    fontWeight: 'bold',
  },
  closed: {
    color: '#ff0000',
    fontWeight: 'bold',
  },
  actionButtons: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: 0,
      marginTop: theme.spacing(1),
      alignSelf: 'center',
      justifyContent: 'space-around',
      width: '100%',
    },
  },
  actionButton: {
    marginRight: 20,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 0px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
  },
  mapButton: {
    marginTop: theme.spacing(2),
    boxShadow: '0px 0px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  //This is the desktop one
  mapButton2: {
    width: '140px',
    height: '120px',
    marginRight: theme.spacing(30),
    marginTop: theme.spacing(5),
    boxShadow: '0px 0px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const getStoreLocation = async (id) => {
  return api
    .getStore(id)
    .then((res) => {
      return { long: res.data.longitude, lat: res.data.latitude };
    })
    .catch((e) => {
      console.log(e);
    });
};
/*
const getFollowed = async (userId) => {
  return api
    .getUser(userId)
    .then((res) =>
      res._embedded.followedStores.map((store) =>
        store._links.self.href.replace("{?projection}", "")
      )
    );
};

const updateFollowed = async (userId, following) => {
  return api
    .updateUser(userId, { followedStores: following })
    .then((res) =>
      res.data._embedded.followedStores
        ? res.data._embedded.followedStores.map((store) =>
            store._links.self.href.replace("{?projection}", "")
          )
        : []
    )
    .catch((error) => {
      throw error;
    });
};

*/
function StoreDetails(props) {
  const { storeDetails, jumpToReview, /*mobile*/ loading, open } = props;
  //const id = storeDetails._links.self.href
  //const userId = cookies.getUserID();

  const classes = useStyles();
  //const loggedIn = cookies.getUserID()!==undefined && cookies.getUserID()!==null

  // const [following, setFollowing] = useState([]);
  // const followed = following.includes(id);

  const [openMap, setOpenMap] = useState(false); //the mobile one
  const [mapOpen, setMapOpen] = useState(false); //desktop one
  const [storeCoords, setStoreCoords] = useState({ long: null, lat: null });

  useEffect(() => {
    const storeID = parseIdFromLink(storeDetails._links.self.href);

    const checkAndSetCoords = async () => {
      let coords = storeDetails.address.localCoordinates || (await getStoreLocation(storeID)); // Only fetch if missing local coordinates
      setStoreCoords(coords);
    };
    checkAndSetCoords();
    // getStoreLocation(storeID).then((coords) => {
    //   setStoreCoords(coords);
    // });
    // eslint-disable-next-line
  }, []);

  const storeLogoURL = makeImageKitURL(storeDetails.storeImage);

  const showStaticMap =
    storeDetails.displayMap === null || storeDetails.displayMap
      ? (storeCoords.long && storeCoords.lat) || storeDetails.address.postalCode
      : false;

  return (
    <div className={classes.storeDetailsRoot}>
      <div className={classes.main}>
        <div className={classes.leftContainer}>
          <div className={classes.info}>
            <div className={classes.logoContainer}>
              <img src={storeLogoURL} alt="store-logo" className={classes.logo} />
            </div>
            <div className={classes.detailsContainer}>
              <Typography variant="h2" className={classes.storeName}>
                {storeDetails.name}
              </Typography>
              <div className={classes.rating}>
                <Rating
                  name="read-only"
                  value={storeDetails.avgRating}
                  precision={0.5}
                  size="small"
                  className={classes.stars}
                  readOnly
                />
                <Typography variant="caption">
                  {`${Number(storeDetails.avgRating).toFixed(2)} (${storeDetails.numOfReviews} reviews)`}
                </Typography>
              </div>
              <Typography variant="body2">{storeDetails.storeType}</Typography>
              <Typography variant="body2">
                {`${storeDetails.address.city}, ${abbreviate(storeDetails.address.province)}`}
              </Typography>
              <Typography variant="body2">{formatPhoneNumber(storeDetails.phone)}</Typography>
              {/* <Typography variant="body2">{storeDetails.email}</Typography> */}
              {storeDetails.hours !== null && (
                <div className={classes.openStatus}>
                  {loading ? <Typography> </Typography> : <OpenStatusBadge open={open} />}
                  {loading ? <Typography> </Typography> : <OpenStatusText open={open} hours={storeDetails.hours} />}
                </div>
              )}

              {/*<Button
              className={classes.followButton}
              style={{
                backgroundColor: followed
                  ? theme.palette.common.darkGrey
                  : theme.palette.primary.main,
              }}
              onClick={() => {
                if (!loggedIn) {
                  setFollowing(true);
                  window.location.assign("/login");
                } else {
                  const set = new Set(following);

                  if (followed) {
                    set.delete(id);
                  } else {
                    set.add(id);
                  }

                  updateFollowed(userId, Array.from(set))
                    .then((res) => {
                      setFollowing(res);
                    })
                    .catch((err) => {
                    });
                }
              }}
            >
                {followed ? "Following" : "Follow"}
              </Button>
            */}
            </div>
          </div>
          <div className={classes.actionButtons} id="store-actions">
            <Tooltip title="Call" bottom="true" arrow>
              <Fab
                color="default"
                size="small"
                aria-label="call"
                className={classes.actionButton}
                href={`tel:${storeDetails.phone}`}
                style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
              >
                <Call />
              </Fab>
            </Tooltip>
            <Tooltip title="Email" bottom="true" arrow>
              <Fab
                color="default"
                size="small"
                aria-label="email"
                className={classes.actionButton}
                href={`mailto:${storeDetails.email}`}
                style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
              >
                <Email />
              </Fab>
            </Tooltip>
            <Tooltip title="Read Reviews" bottom="true" arrow>
              <Fab
                color="default"
                size="small"
                aria-label="rate"
                className={classes.actionButton}
                onClick={() => {
                  window.scroll(0, 325);
                  jumpToReview();
                }}
              >
                <StarRate />
              </Fab>
            </Tooltip>

            <ShareUrlBtn className={classes.actionButton} />

            {storeDetails?.website && (
              <Tooltip title="Website" bottom="true" arrow>
                <Fab
                  color="default"
                  size="small"
                  aria-label="website"
                  className={classes.actionButton}
                  href={'//' + storeDetails.website}
                  target="_blank"
                  style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
                >
                  <LanguageIcon />
                </Fab>
              </Tooltip>
            )}
          </div>
          {showStaticMap && (
            /* Mobile View Map Btn */
            <Button variant="contained" color="primary" className={classes.mapButton} onClick={() => setOpenMap(true)}>
              <MapIcon style={{ marginRight: '10px' }} />
              View Map
            </Button>
          )}
        </div>
        {/*NON COLLAPSED DESKTOP VIEW*/}
        {showStaticMap && !openMap && (
          <div
            className={classes.rightContainer}
            hidden={mapOpen}
            variant="contained"
            color="primary"
            //className={classes.mapButton2}
            onClick={() => setMapOpen(true)}
          >
            <span className={classes.rightContainerText}>
              <MapIcon style={{ marginRight: '10px' }} />
              View Map
            </span>
          </div>
        )}
        {mapOpen && showStaticMap && (
          <div className={classes.map}>
            <StaticMap
              className={classes.mapOpen}
              address={storeDetails.address}
              storeName={storeDetails.name}
              storeCoords={storeCoords}
            ></StaticMap>
          </div>
        )}
      </div>
      <Modal
        className={classes.modal}
        open={openMap}
        onClose={() => {
          setOpenMap(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openMap}>
          <div className={classes.modalPaper}>
            <StaticMap
              address={storeDetails.address}
              storeName={storeDetails.name}
              storeCoords={storeCoords}
            ></StaticMap>
            <div className={classes.actionButtons}>
              <Button variant="contained" onClick={() => setOpenMap(false)} className={classes.cancelButton}>
                Close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default StoreDetails;
